import React, { useEffect, useState, useRef } from 'react'
import { Button, Form, Input, Modal, notification, Select, Space, Table, Upload } from 'antd'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import { clearImportExamineeScoreData, downloadExamineeScoreFile, examineeScoreList, updateExamineesScore } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'
import AppButton from '../../components/AppButton'
import PageStatus from '../../components/PageStatus'
import { downloadFile, throttle } from '../../plugins/utils'
import DeIdentification from '../../components/DeIdentification'
import ImportExamScoreModal from './ImportExamScoreModal'
function ImportExamScore () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [form] = Form.useForm()
  const [tableData, setTableData] = useState({})
  const [columns, setColumns] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pageStatus, setPageStatus] = useState({ text: '', status: '' })
  const envInfo = useSelector(state => state.env.envInfo)
  const editExamineeInfoModalRef = useRef()

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageNum, pageSize])

  const getTableData = (searchData = {}) => {
    const params = {
      ...searchData,
      scoreStatus: searchData.scoreStatus ? Number(searchData.scoreStatus) : undefined,
      examId: selectedExam.id,
      orderBy: '_id',
      orderType: 'DESC',
      pageSize,
      pageNum
    }

    setPageStatus({ text: '正在加载', status: 'loading' })
    examineeScoreList(params).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        setTableData(res.data)

        if (res.data.elements?.length > 0) {
          setColumns([
            ...Object.keys(res.data.elements[0]).filter(key => {
              return key !== '_id' && key !== 'examId'
            }).map(key => {
              return {
                title: key,
                key: key,
                dataIndex: key,
                render: (record) => {
                  return (<DeIdentification value={ record } type={ key }></DeIdentification>)
                }
              }
            }),
            {
              title: '操作',
              width: '110px',
              dataIndex: 'operation',
              key: 'operation',
              render: (data, row) => {
                return (<>
                  <Space className="d-flex justify-content-end">
                    <Button
                      className={ 'ml-4' }
                      type={ 'primary' }
                      size={ 'small' }
                      onClick={ showEditImportExamScoreModal.bind(this, row) }
                  >查看</Button>
                  </Space>
                </>)
              }
            }
          ])
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setPageStatus({ text: '', status: '' })
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生成绩列表失败'
      })
    })
  }

  const downloadExamineeScore = () => {
    downloadExamineeScoreFile({ id: selectedExam.id }).then(res => {
      downloadFile(res, 'xlsx', `${selectedExam.name}_笔试成绩`)
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const showEditImportExamScoreModal = (row) => {
    editExamineeInfoModalRef.current.showModal(row)
  }

  const beforeUpload = () => {
    setPageStatus({ text: '正在导入', status: 'loading' })
  }

  const uploadPersonImageChange = ({ file }) => {
    const res = file.response
    if (res) {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        getTableData()
        Modal.success({
          title: '操作成功',
          content: res.msg
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  const showClearTableDataModal = () => {
    Modal.confirm({
      title: '清除数据',
      content: '确定要清除导入的成绩数据吗？',
      onOk: () => {
        clearTableDataAction()
      }
    })
  }

  const clearTableDataAction = () => {
    setPageStatus({ text: '正在清除数据', status: 'loading'})
    clearImportExamineeScoreData({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: ''})
      if (res.code === ResponseCode.success) {
        getTableData()
        notification.success({
          message: '操作成功',
          description: '已清除导入成绩数据'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setPageStatus({ text: '', status: ''})
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，清除数据失败'
      })
    })
  }

  const handleSearch = (values) => {
    setPageNum(1)
    getTableData(values)
  }

  return (<>
    <SelectExam>
      <PageStatus pageStatus={ pageStatus.status } text={ pageStatus.text }>
        <Form
          layout={ 'inline' }
          form={ form }
          onValuesChange={ throttle(handleSearch, 500) }
          initialValues={{
            name: '',
            cardNumber: '',
            scoreStatus: ''
          }}
        >
          <Form.Item
            label={ '考生姓名' }
            name={ 'name' }
            trigger={ 'onInput' }
          >
            <Input placeholder={ '输入考生姓名查询' } style={{ width: 140 }}></Input>
          </Form.Item>
          <Form.Item
            label={ '身份证号' }
            name={ 'cardNumber' }
            trigger={ 'onInput' }
          >
            <Input placeholder={ '输入考生身份证号查询' }></Input>
          </Form.Item>
          <Form.Item
            label={ '查询字段' }
            name={ 'scoreStatus' }
          >
            <Select
              options={ [
              { label: '全部', value: '' },
              { label: '已查询', value: '1' },
              { label: '未查询', value: '0' },
            ] }
              style={{ width: 100 }}
            ></Select>
          </Form.Item>
        </Form>
        <div className={ 'mb-3 d-flex align-items-center justify-content-end' }>
          {
            selectedExam.examinationStatus < 3
              ? (
                <Space>
                  <AppButton
                    authCode={ 'button_bscjdr-qcsj' }
                    type={ 'primary' }
                    ghost
                    danger
                    onClick={ showClearTableDataModal }
                  >
                    清除数据
                  </AppButton>
                  <ComponentVisibleByAuth authCode={ 'button_bscjdr-drkssj' }>
                    <Upload
                      name={ 'file' }
                      action={ `${ envInfo.picDomain }/${updateExamineesScore}` }
                      headers={ {
                        authorization: window.sessionStorage.getItem('access_token')
                      } }
                      data={ {
                        examId: selectedExam.id
                      } }
                      disabled={ pageStatus.status === 'loading' }
                      maxCount={ 1 }
                      showUploadList={ false }
                      beforeUpload={ beforeUpload }
                      onChange={ uploadPersonImageChange }
                    >
                      <AppButton type={ 'primary' }>导入考生成绩</AppButton>
                    </Upload>
                  </ComponentVisibleByAuth>
                  <AppButton authCode={ 'button_bscjdr-xzkscj' } type={ 'primary' } onClick={  downloadExamineeScore }>下载考生成绩</AppButton>
                </Space>
              )
              : <></>
          }
        </div>

        <Table
          loading={ pageStatus.status === 'loading' }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ record => record._id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </PageStatus>
    </SelectExam>
    <ImportExamScoreModal
        ref={ editExamineeInfoModalRef }
      ></ImportExamScoreModal>
  </>)
}

export default ImportExamScore
